import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

var router = new Router({
   mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "",
      component: () =>
        import(/* webpackChunkName:"Home" */ "../views/Home.vue"),
      meta: {
        title: "首页",
      },
      redirect: {
        name: "index"
      },
      children: [
        {
          path: "/",
          name: "index",
          meta: {
            title: "ABB机器人数字化云展厅",
            auth: false,
            icon: ""
          },
          component: () =>
            import(/* webpackChunkName:"index" */ "../views/Index.vue")
        },
        {
          path: "/product",
          component: () =>
            import(/* webpackChunkName:"product" */ "../views/Product.vue"),
          meta: {
            title: "",
          },
        },
        {
          path: "/article",
          component: () =>
            import(/* webpackChunkName:"article" */ "../views/Article.vue"),
          meta: {
            title: "",
          },
        }]
    },

    {
      path: "/login",
      component: () =>
        import(/* webpackChunkName:"login" */ "../views/Login.vue"),
      meta: {
        title: "",
      },
    },

    {
      path: "/reg",
      component: () =>
        import(/* webpackChunkName:"reg" */ "../views/Reg.vue"),
      meta: {
        title: "",
      },
    },
    {
      path: "/down",
      component: () =>
        import(/* webpackChunkName:"down" */ "../views/Down.vue"),
      meta: {
        title: "",
      },
    },
    {
      path: "/xieyi",
      component: () =>
        import(/* webpackChunkName:"xieyi" */ "../views/Xieyi.vue"),
      meta: {
        title: "",
      },
    },
    {
      path: "/repass",
      component: () =>
        import(/* webpackChunkName:"repass" */ "../views/Repass.vue"),
      meta: {
        title: "",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  
  next();
});
export default router;
