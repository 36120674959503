const lang = {
  zh: {
    home: {
      webname: "ABB机器人数字化云展厅",
      home: "首页",
      login: "登录",
      beian: "京ICP备18010306号-3",
      tc:'退出登录'
    },
    article: {
      jianHuaCaoZuo: "简化操作",
      tiGaoXiaoLu: "提高效率",
      gaiShanZhiLiang: "改善质量",
      tiShenRouXing: "提升柔性",
      chanPinXinHao: "产品型号",
      fuZhong: "负重（KG）",
      gongZuoFanWei: "工作范围（m）",
      chongFuDingWeiJinDu: "重复定位精度（mm）",
      anZhuangFangShi: "安装方式",
      fangHuDengJi: "防护等级",
      zhouShu: "轴数",
      kongZhiShu: "控制器",
      fanHui: "返回",
    },
    login: {
      tips: "用户登录",
      zhuCeZhangHu: "注册账户：",
      mima: "密    码：",
      wangJiMiMa: "忘记密码？",
      zhangHuZhuCe: "账户注册？",
      dianJiCiChu: "点击此处",
    },
    reg: {
      firstName: "姓",
      lastName: "名",
      username: "姓名",
      email: "注册邮箱",
      password: "密码",
      rpassword: "密码确认",
      phoneNumber: "移动电话",
      verificationCode: "验证码",
      company: "公司",
      position: "职位",
      industry: "行业请选择",
      yudu: "我已阅读并同意",
      zc: "ABB隐私政策",
      reg: "注 册",
      yy: "已有账号？",
      ljdl: "立即登录",
      wsabb: "我是ABB员工",
    },
    product: {
      chanPinJieShao: "产品介绍",
      canShuXinXi: "参数信息",
      ziLiaoXiaZai: "资料下载",
      fanHui: "返回首页",
    },

    repass: {
      tips: "请单击下面的按钮以接收有关如何重置密码的说明。",
      zhuCeZhangHao: "注册账号*",
      emailTips: "请输入有效的电子邮件地址.",
      faSongYouJian: "发送邮件",
    },

    reset: {
      title: "重置密码",
      tips: "请单击下面的按钮以接收有关如何重置密码的说明。",
      newpassword: "新密码*",
      repassword: "重复密码*",
      sure: "确定",
    },
  },
  en: {
    home: {
      webname: "ABB Robotics Digital Cloud Showroom",
      home: "Home",
      login: "Login",
      beian: "Jing ICP Bei No. 18010306-3",
      tc:'Logout'
    },
    article: {
      jianHuaCaoZuo: "Simplicity",
      tiGaoXiaoLu: "Productivity",
      gaiShanZhiLiang: "Quality",
      tiShenRouXing: "Flexibility",
      chanPinXinHao: "Articulated robots",
      fuZhong: "Payload（KG）",
      gongZuoFanWei: "Reach（m）",
      chongFuDingWeiJinDu: "Pos. rep. （mm）",
      anZhuangFangShi: "Mounting",
      fangHuDengJi: "Protection",
      zhouShu: "Axes",
      kongZhiShu: "Controller",
      fanHui: "BACK",
    },
    login: {
      tips: "USER LOGIN",
      zhuCeZhangHu: "E-MAIL:",
      mima: "PASSWORD:",
      wangJiMiMa: "FORGOT PASSWORD?",
      zhangHuZhuCe: "NEED AN ACCOUNT?",
      dianJiCiChu: "SIGN UP",
      tc:'SIGN OUT'
    },
    reg: {
      firstName: "FIRST NAME",
      lastName: "LAST NAME",
      username: "USERNAME",
      email: "E-MAIL",
      password: "PASSWORD",
      rpassword: "REPEAT PASSWORD",
      phoneNumber: "PHONE NUMBER",
      verificationCode: "VERIFICATION CODE",
      company: "COMPANY",
      position: "TITLE",
      industry: "INDUSTRY",
      yudu: "I HAVE READ AND AGREE TO ",
      zc: "ABB PRIVACY POLICY",
      reg: "SIGN UP",
      yy: "EXISTING USER,",
      ljdl: "LOGIN NOW",
      wsabb: "ABB STAFF",
    },
    product: {
      chanPinJieShao: "Introduction",
      canShuXinXi: "Parameters",
      ziLiaoXiaZai: "Download",
       fanHui: "BACK",
    },

    repass: {
      title: "FORGOT PASSWORD?",
      tips: "Pleaser click on the button below to receiveinstructions on how to reset your password.",
      zhuCeZhangHao: "E-MAIL ADDRESS*",
      emailTips: "PLEASE ENTER A VALID EMAIL ADDRESS.",
      faSongYouJian: "SEND MAIL",
    },
    reset: {
      title: "FORGOT PASSWORD?",
      tips: "Pleaser click on the button below to receiveinstructions on how to reset your password.",
      newpassword: "new pass*",
      repassword: "repeat pass*",
      sure: "SURE",
    },
  },
};

export default lang;
