/**
 * @description 弹出层时调用，锁定禁止背景滚动
 */
export let lock = () => {
  try {
    let body = document.querySelector("body");
    body.style.height = "100vh";
    body.style.overflow = "hidden";
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description 解除锁定，可滑动
 */
export let unlock = () => {
  try {
    let body = document.querySelector("body");
    body.style.height = "auto";
    body.style.overflow = "visible";
  } catch (error) {
    console.log(error);
  }
};

/**
 * @description 动态加载js库
 * @param {*} url js地址
 * @param {*} cb 回调
 */
export function loadJs(url, cb) {
  var script = document.createElement("script");
  script.async = false;
  script.src = url;
  script.onload = function () {
    cb && cb();
  };
  document.body.appendChild(script);
}

// 时间戳转时分秒00:00:00格式
export const formatDuring = function (millisecond) {
  if (millisecond < 0) return;
  var hours = parseInt(
    (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = parseInt((millisecond % (1000 * 60)) / 1000);
  return (
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
};

// 时间戳转天时分格式
export const formatDate = function (second) {
  var str = "";
  var days = parseInt(second / (60 * 60 * 24));
  var hours = parseInt((second % (60 * 60 * 24)) / (60 * 60));
  var minutes = parseInt((second % (60 * 60)) / 60);
  var seconds = parseInt(second % 60);
  if (days > 0) {
    str = str + days + "天";
  }
  if (hours > 0) {
    str = str + hours + "小时";
  }
  if (minutes > 0) {
    str = str + minutes + "分钟";
  }
  if (seconds > 0) {
    str = str + seconds + "秒";
  }
  return str;
};

// 获取cookie

export const getCookie = function (name) {
  var strcookie = document.cookie; //获取cookie字符串
  var arrcookie = strcookie.split("; "); //分割
  //遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split("=");
    if (arr[0] == name) {
      return arr[1];
    }
  }
  return "";
};

/**
 * @description 获取search中的值
 * @param {*} name
 * @returns {Sting} - 返回值
 */
export const getQueryString = function (name) {
  try {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let href = "";
    if (location.hash.split("?")[1]) {
      href = location.hash.split("?")[1];
    } else if (window.location.search.substr(1)) {
      href = window.location.search.substr(1);
    }
    var r = href.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  } catch (e) {
    return null;
  }
};



export let lang = function(){
  return location.href.includes('en')?'en':'zh'
}
