<template>
  <div id="app">
    <!-- 缓存路由 -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  async created() {},
  async mounted() {},
  methods: {},
};
</script>
<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: ABB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
