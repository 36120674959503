import http from "../utils/http.js";
import API from "../config/index.js";

import { lang } from "../utils/utils";

//get请求 产品树
export let home = () => {
  return http.get(`${API}prd/tree`, {
    params: {
      lang: lang(),
    },
  });
};

//get请求 产品详情
export let detail = (id) => {
  return http.get(`${API}prd/${id}`, {
    params: { lang: lang() },
  });
};

//登录
export let login = (username, password) => {
  return http.post(`${API}system/login`, {
    username: username,
    password: password,
    lang: lang(),
    p: "WEB",
  });
};

//注册
export let reg = (params) => {
  return http.post(`${API}u/signup`, { lang: lang(), ...params });
};

//下载
export let down = (id) => {
  console.log(id);
  return http.get(`${API}system/productAttribute/${id}/doc`, {
    params: { lang: lang() },
  });
};

//jsonp请求 demo
export let shopBrowse = async (taskCode) => {
  return http.jsonp({
    url: `${API}/xxx.jsonp?_=${taskCode}`,
    data: { callback: `callBack` },
  });
};

// 获取用户详细信息
export function getInfo() {
  return http.get(`${API}system/user/getInfo`, { lang: lang() });
}

// 系统配置
export function cfg() {
  return http.get(`${API}u/cfg`, {
    lang: lang(),
  });
}

// 获取验证码
export let mc = (phone) => {
  return http.post(`${API}u/mc`, { lang: lang(), phone: phone });
};

// 获取验证码
export let fpmail = (mail) => {
  return http.post(`${API}u/rpm`, { lang: lang(), to: mail });
};

//重置密码
export let rp = (s, password) => {
  return http.post(`${API}/u/rp`, { lang: lang(), s: s, password: password });
};
