import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/common.css";
import VueI18n from "vue-i18n";
import lang from "./config/lang.js";
import {getQueryString} from "./utils/utils.js";

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueI18n);

let langEnv = "zh";

let clang = getQueryString("lang")
let envLang = window.navigator.language.slice(0, 2)
langEnv = clang || envLang

const i18n = new VueI18n({
  locale: langEnv,
  messages: lang,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
