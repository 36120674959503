// wiki https://www.kancloud.cn/yunye/axios/234845
import axios from "axios";
import { jsonp } from "./jsonp";

//设置axios为form-data
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function (data) {
//     let ret = ''
//     for (let it in data) {
//       ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//     }
//     return ret
// }]

axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
axios.defaults.crossDomain = true; //设置cross跨域 并设置访问权限
axios.defaults.withCredentials = true; //允许跨域携带cookie信息
axios.defaults.timeout = 5000; //请求超时
// 创建axios实例
var http = axios.create({
  baseURL: "", // api的base_url
});

// request拦截器
http.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    // 是否需要设置 token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    //拦截放行
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// respone拦截器
http.interceptors.response.use(
  (response) => {
    const data = response.data;
    //拦截放行
    return Promise.resolve(data);
  },
  (error) => {
    console.warn(error);
    return Promise.reject(error);
  }
);

http.jsonp = jsonp;

export default http;
