const TokenKey = "token";

export function getToken() {
  let token = localStorage.getItem("token");
  return token;
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
